import { useLoaderData } from 'react-router-dom';
import {
	getMagnoliaBaseDamUrl,
	getMagnoliaBaseUrl,
	formatBytes,
} from '../../util';
import { UploadedAssetView } from './UploadedAssetView';
import { ExternalAssetView } from './ExternalAssetView';
import { ExternalAssetVideoView } from './ExternalAssetVideoView';
import { ErrorPage } from '../ErrorPage';
import { Helmet } from 'react-helmet';
import { Text } from '@dsx/react';
import { ContentContainer } from '../../components/Layout/ContentContainer';
import { UploadedAssetCalendarView } from './UploadedAssetCalendarView';
import type { CollateralAsset } from './types';

export const CollateralLibraryAssetDetailPage = () => {
	const asset = useLoaderData();
	const {
		assetType,
		assetDescription,
		assetDisplayName,
		category,
		thumbnailImage,
		'mgnl:created': created,
		'mgnl:lastModified': lastModified,
		archived,
		internalUseOnly,
		showOnSite,
	} = asset as CollateralAsset;
	const showDetailPage = showOnSite.includes('tos');

	if (!showDetailPage) {
		return <ErrorPage />;
	}

	const magnoliaBaseDamUrl = getMagnoliaBaseDamUrl();
	const thumbnailImageUrl = `${magnoliaBaseDamUrl}${thumbnailImage['@link']}`;
	const publishedDate = new Date(lastModified).toLocaleDateString('en-US', {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
	});
	const createdDate = new Date(created).toLocaleDateString('en-US', {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
	});
	const categoryDisplayName = category.name;

	const renderAssetDetailView = () => {
		if (assetType.linkToUploadedAsset && assetType.field === 'uploadedAsset') {
			const linkToAsset =
				assetType.linkToUploadedAsset?.uploadedAssetLink['@link'];
			const assetUrl = linkToAsset && `${magnoliaBaseDamUrl}${linkToAsset}`;
			const fileSize =
				assetType.linkToUploadedAsset.uploadedAssetLink.metadata?.fileSize ||
				'0';
			const fileExtension =
				assetType.linkToUploadedAsset.uploadedAssetLink.metadata?.format ||
				'N/A';
			const formattedBytes = formatBytes(parseInt(fileSize));
			const formatName = assetType.linkToUploadedAsset.format.name;

			return (
				<UploadedAssetView
					category={categoryDisplayName}
					headline={assetDisplayName}
					description={assetDescription}
					assetUrl={assetUrl}
					publishedDate={publishedDate}
					fileSize={formattedBytes}
					fileExtension={fileExtension}
					createdDate={createdDate}
					thumbnailImage={thumbnailImageUrl}
					format={formatName}
				/>
			);
		}
		if (
			assetType.linkToUploadedAssetCalendar &&
			assetType.field === 'uploadedAssetCalendar'
		) {
			const linkToAsset =
				assetType.linkToUploadedAssetCalendar?.uploadedAssetLink['@link'];
			const assetUrl = linkToAsset && `${magnoliaBaseDamUrl}${linkToAsset}`;
			return (
				<UploadedAssetCalendarView
					category={categoryDisplayName}
					headline={assetDisplayName}
					description={assetDescription}
					assetUrl={assetUrl}
					thumbnailImage={thumbnailImageUrl}
				/>
			);
		}
		if (assetType.linkToExternalAsset && assetType.field === 'externalAsset') {
			const assetUrl = assetType.linkToExternalAsset.externalAssetLink;

			return (
				<ExternalAssetView
					category={categoryDisplayName}
					headline={assetDisplayName}
					description={assetDescription}
					assetUrl={assetUrl}
					publishedDate={publishedDate}
					createdDate={createdDate}
					thumbnailImage={thumbnailImageUrl}
				/>
			);
		}
		if (
			assetType.linkToExternalAssetVideo &&
			assetType.field === 'externalAssetVideo'
		) {
			const videoId = assetType.linkToExternalAssetVideo.videoId;
			const videoType = assetType.linkToExternalAssetVideo.videoType;
			const videoDuration = assetType.linkToExternalAssetVideo.videoDuration;
			return (
				<ExternalAssetVideoView
					category={categoryDisplayName}
					headline={assetDisplayName}
					description={assetDescription}
					publishedDate={publishedDate}
					createdDate={createdDate}
					thumbnailImage={thumbnailImageUrl}
					videoId={videoId}
					videoType={videoType}
					videoDuration={videoDuration}
				/>
			);
		}
		return null;
	};

	return (
		<ContentContainer>
			<Helmet>
				<title>{assetDisplayName} | Tell Our Stories</title>
				<meta name="description" content={assetDescription} />
				<meta
					name="robots"
					content={
						archived || internalUseOnly ? 'noindex, nofollow' : 'index, follow'
					}
				/>
				<meta property="og:type" content="website" />
				<meta property="og:site_name" content="Tell Our Stories" />
				<meta property="og:url" content={window.location.href} />
				<meta property="og:title" content={assetDisplayName} />
				<meta
					property="og:description"
					content={`${assetDescription} | Tell Our Stories`}
				/>
				<meta name="image" property="og:image" content={thumbnailImageUrl} />
			</Helmet>
			{archived || internalUseOnly ? (
				<Text>Asset is archived or for internal use only</Text>
			) : (
				renderAssetDetailView()
			)}
		</ContentContainer>
	);
};

export const collateralLibraryAssetDetailPageLoader = async ({
	params,
}: {
	params: {
		assetId?: string;
	};
}) => {
	const { assetId } = params;
	const magnoliaBaseUrl = getMagnoliaBaseUrl();
	const res = await fetch(
		`${magnoliaBaseUrl}${process.env.MGNL_API_COLLATERAL_LIBRARY}/${assetId}`
	);

	return res.json();
};
